import marriageBanner from "@/assets/images/prestations/privatisation-lieu.jpg";
import salonVilleDAvray from "@/assets/images/prestations/salon-ville-d-avray.png";
import { type ServiceSection } from "../types";
import { pastWorkImages } from "./past-work-images";

export const privatisationService: ServiceSection = {
  id: "privatisation-lieu",
  bannerImage: marriageBanner,
  bannerTitle: "Privatisation Salon de Coiffure",
  keywords: [
    "privatisation salon villejuif",
    "salon privé ville d'avray",
    "evjf coiffure val de marne",
    "privatisation salon 94800",
    "location salon coiffure 92410",
    "moment détente entre copines",
    "privatisation salon coiffure dimanche",
  ],
  firstSection: {
    imagePath: salonVilleDAvray.src,
    title: "Un Moment de Détente Entre Copines - Privatisation de Salon",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Offrez-vous une expérience unique en privatisant nos salons de coiffure à Villejuif (Val-de-Marne) ou Ville d'Avray (Hauts-de-Seine). Un espace élégant et intimiste, réservé exclusivement pour vous et vos amies pendant 2 heures, disponible en soirée ou le dimanche.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Idéal pour les enterrements de vie de jeune fille, anniversaires, ou simplement pour partager un moment privilégié entre amies, nos salons Alexandra Grey en Ile de France vous accueillent dans un cadre raffiné et haut de gamme.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          { text: "Notre promesse", isBold: true },
          {
            text: " : une parenthèse de bien-être et de convivialité, sur mesure, pour un minimum de 5 personnes.",
          },
        ],
      },
    ],
  },
  secondSection: {
    title: "Services Inclus dans la Privatisation",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Nous vous proposons une formule complète pour une expérience bien-être inoubliable :",
          },
        ],
      },
      {
        type: "list",
        segments: [{ text: "Nos prestations comprennent :" }],
        items: [
          "Prestations de coiffure personnalisées",
          "Massages relaxants (soin balinet)",
          "Manucure pour des mains parfaites",
          "Cocktail dînatoire ou goûter selon l'horaire choisi",
          "Accès privatif au salon pendant 2 heures",
        ],
      },
    ],
  },
  thirdSection: {
    title: "Organisation Sur Mesure",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Chaque privatisation est unique et fait l'objet d'un devis personnalisé. Notre équipe est à votre écoute pour organiser votre événement selon vos envies et votre budget.",
          },
        ],
      },
      {
        type: "list",
        segments: [{ text: "Informations pratiques :" }],
        items: [
          "Minimum 5 personnes requises pour la privatisation",
          "Disponibilité en soirée ou le dimanche",
          "Durée : 2 heures d'accès exclusif",
          "Devis personnalisé selon vos choix de prestations",
          "Réservation à l'avance nécessaire",
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Contactez-nous pour obtenir votre devis personnalisé et créer ensemble votre moment de détente idéal.",
          },
        ],
      },
    ],
  },
  fourthSection: {
    title: "TRAVAUX ANTÉRIEURS",
    images: pastWorkImages,
  },
};
