import { type ServiceSection } from "../types";
import colorationVegetale from "@/assets/images/prestations/coloration-vegetale.webp";
import colorationVegetaleIntro from "@/assets/images/prestations/coloration-intro.jpg";

export const colorationVegetaleService: ServiceSection = {
  id: "coloration-vegetale",
  bannerImage: colorationVegetale,
  bannerTitle: "COLORATION VÉGÉTALE",
  keywords: ["coloration", "végétale", "naturel", "bio", "soin"],
  firstSection: {
    imagePath: colorationVegetaleIntro.src,
    title: "LA COLORATION VÉGÉTALE",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "La coloration végétale est une alternative naturelle et bienveillante aux colorations chimiques traditionnelles. Composée uniquement de plantes tinctoriales, elle permet de colorer vos cheveux tout en les respectant et en les fortifiant.",
          },
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Cette prestation exclusive est disponible uniquement dans notre salon de ",
          },
          {
            text: "Ville d'Avray",
            isBold: true,
          },
          {
            text: ", où nos experts sont spécialement formés à cette technique ancestrale. ",
          },
          {
            text: "Réservez directement votre coloration végétale",
            isBold: true,
          },
          {
            text: " en ",
          },
          {
            text: "cliquant ici",
            isBold: true,
            link: "https://www.planity.com/alexandra-grey-92410-ville-davray",
          },
          {
            text: ".",
          },
        ],
      },
    ],
  },
  secondSection: {
    title: "Pourquoi choisir la coloration végétale ?",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "La coloration végétale représente une approche holistique et naturelle du soin capillaire. Cette technique ancestrale, perfectionnée par nos experts, offre une alternative saine et efficace aux colorations chimiques traditionnelles. Découvrez les nombreux avantages qui font de cette prestation un choix privilégié pour prendre soin de vos cheveux :",
          },
        ],
      },
      {
        type: "list",
        segments: [{ text: "" }],
        items: [
          "100% naturelle et sans produits chimiques : uniquement des plantes tinctoriales sélectionnées",
          "Protection et renforcement de la fibre capillaire : nourrit en profondeur tout en colorant",
          "Brillance et vitalité exceptionnelles : des cheveux visiblement plus sains",
          "Couverture optimale des cheveux blancs : un résultat naturel et harmonieux",
          "Tenue longue durée : une coloration qui s'estompe progressivement sans effet racines",
          "Soin doux et respectueux : parfaitement adaptée aux cuirs chevelus les plus sensibles",
        ],
      },
    ],
  },
  thirdSection: {
    title: "Le déroulement de la séance",
    content: [
      {
        type: "paragraph",
        segments: [
          {
            text: "Une coloration végétale est un véritable rituel de soin qui nécessite expertise et précision. Nos spécialistes à Ville d'Avray vous accompagnent tout au long de ce moment de bien-être, en suivant un protocole minutieux pour garantir un résultat optimal :",
          },
        ],
      },
      {
        type: "list",
        segments: [{ text: "" }],
        items: [
          "Consultation personnalisée : analyse approfondie de vos cheveux et échange sur vos souhaits",
          "Création sur-mesure : élaboration d'un mélange unique de plantes adapté à votre objectif",
          "Application experte : pose méticuleuse de la coloration pour une répartition homogène",
          "Phase de pose : temps d'action adapté pour une pigmentation optimale",
          "Soin final : rinçage délicat et application d'un soin spécifique pour sublimer le résultat",
        ],
      },
      {
        type: "paragraph",
        segments: [
          {
            text: "Pour une expérience complète et un résultat optimal, prévoyez ",
          },
          {
            text: "1h30 à 2h",
            isBold: true,
          },
          {
            text: ". Cette durée varie selon la longueur et l'épaisseur de votre chevelure, permettant à nos experts de vous offrir un accompagnement personnalisé et un résultat à la hauteur de vos attentes.",
          },
        ],
      },
    ],
  },
  fourthSection: {
    title: "NOS RÉALISATIONS",
    images: [
      {
        src: colorationVegetale,
        alt: "Exemple de coloration végétale 1",
      },
      {
        src: colorationVegetale,
        alt: "Exemple de coloration végétale 2",
      },
      {
        src: colorationVegetale,
        alt: "Exemple de coloration végétale 3",
      },
    ],
  },
};
